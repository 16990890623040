.affected-devices {
  display: flex;
  flex-direction: column;
  color: white;
  padding-top: 20px;
  padding-left: 14px;
  justify-content: space-between;

  .affected-devices-title {
    font-size: 16px;
    font-family: ATTAleckSansMd;
  }

  .device-number {
    font-size: 26px;
    font-family: ATTAleckSansMd;
  }

  .trend-chart {
    display: flex;

    .trend-chart-line {
      padding-top: 0;
      display: flex;
      flex-direction: column-reverse;

      .trend-chart-status {
        color: white;
        bottom: 0;
        right: 0;
        margin-right: 12px;
      }
    }

    .trend-chart-info {
      display: none;
    }
  }

  .no-change {
    .no-change-text {
      padding-right: 16px;
      text-align: right;
      font-family: ATTAleckSansMd;
      font-size: 14px;
      line-height: 20px;
      color: #BDC2C7;
    }
  }
}
