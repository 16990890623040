.anomaly-widgets {
  
  .reset-view {
    cursor: pointer;
    position: absolute;
    right: 68px;

    &:hover {
      background: var(--primary-blue);
      color: #000000;
    }
   }

  .grafana-widgets-container {
    overflow: auto;
    height: calc(100vh - 172px);

    .grafana-anomaly-widget {
      .react-resizable-handle::after {
        border-right-color: #CCCCDC;
        border-bottom-color: #CCCCDC;
      }
    }
  }

  .no-data {
    height: 340px;
    color: #F8FAFB;
    font-size: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: ATTAleckSansBd;
  }
}

@keyframes refresh-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-180deg);
  }
}

