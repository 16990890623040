.app-content {
  padding: 32px 0;
  background: url('../../assets/svg/bg-corner.svg') no-repeat right top black;
  height: calc(100vh - 64px - 80px);
  overflow: auto;
  width: 100%;

  & > div {
    margin: 0 auto;
    width: 90%;

    &.security-dashboard {
      max-width: 1280px;
    }
  }

  .title-container {
    display: flex;
    justify-content: space-between;

    .title {
      color: #F8FAFB;
      font-size: 28px;
      font-weight: 700;
      line-height: 36px;
      margin-bottom: 20px;
    }
  }

  .component-container {
    margin-top: 16px;
    border-radius: 12px;
    background: #212324;
  }
}
