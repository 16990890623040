.table-wrapper {

  .table-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 42px;
    padding: 0 16px;

    .cell-header {
      display: flex;
      align-items: center;
      color: #F8FAFB;
      font-size: 12px;
      font-style: normal;
      line-height: 16px;
      gap: 8px;

      span {
        display: flex;
        align-items: center;
        font-family: ATTAleckSansMd;
      }
    }
  }

  .table-content {
    //height: calc(100vh - 400px);
    //overflow: auto;
    color: #F8FAFB;

    .row {
      padding: 16px;
      display: flex;
      align-items: center;
      border-top: 1px solid #454B52;
      justify-content: space-between;
      position: relative;
      min-height: 32px;
      font-size: 14px;

      .delete {
        display: none;
      }

      &:hover {
         background: #2F3132;;
        .delete {
          display: block;
          position: absolute;
          right: 22px;
          cursor: pointer;
        }
      }
      .cell {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        align-self: baseline;
        font-size: 14px;

        .items {

          display: flex;
          flex-direction: column;
          justify-content: space-between;
          gap: 26px;
          color: #F8FAFB;
          font-family: ATTAleckSans;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;

          .readonly-field {
            display: flex;
            gap: 40px;
          }
        }

        .ant-checkbox-wrapper {
          color: #000;
          font-family: ATTAleckSans;
          font-size: 14px;
          font-weight: 700;
        }
      }
    }

    .no-data {
      font-size: 20px;
      font-family: ATTAleckSansMd;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 60px;
      border-top: 1px solid #454B52;
    }
  }
}