.full-screen-loader {
  position: fixed;
  left: 0;
  top: 0;
  width: 100% !important;
  height: 100%;
  background: rgba(149, 149, 149, 0.4);
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.mtad-logo {
  animation: spin 2s linear infinite;
}
