.footer {
  display: flex;
  flex-direction: column;
  padding: 16px;
  color: white;
  gap: 32px;

  .footer-items {
    display: flex;
    align-items: center;

    .footer-item {
      position: relative;
      padding-right: 25px;
      cursor: pointer;
      font-size: 15px;

      &:after {
        content: "";
        position: absolute;
        right: 12px;
        width: 1px;
        height: 24px;
        background: #707070;
      }

      &:last-child {
        &:after {
          content: none;
        }
      }
    }
  }

  .footer-row {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .footer-text {
      font-size: 12px;
      width: 630px;
    }
  }
}
