.settings-wrapper {

  .setting-title {
    color: #F8FAFB;
    font-size: 24px;
    font-weight: 500;
    line-height: 32px;
    margin-bottom: 24px;
  }

  .group {
    border-top: 1px solid #DCDFE3;

    .group-title {
      margin: 20px 0;
      color: #F8FAFB;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
    }
  }

  .toggle {
    margin-bottom: 30px;
    font-weight: 700;
  }
}
