.menu-container {
  width: 100%;

  ul {
    background: transparent;

    .ant-menu-submenu-selected {
      background-color: var(--primary-blue);
    }

    .ant-menu-title-content a{
      color: #FFFFFF;
      font-family: ATTAleckSansMd;
      font-size: 16px;
    }

    .ant-menu-item-selected, .ant-menu-item-active {
      background-color: transparent !important;
      // border-bottom: 2px solid var(--secondary-blue);

      .ant-menu-title-content a {
        color: var(--secondary-blue);
      }
    }
  }
}

.sub-side-menu {
  border-radius: 10px;
  background: #1D2329;
  filter: drop-shadow(0px 4px 14px rgba(0, 0, 0, 0.75));
  margin-top: 30px;
  padding: 16px;

  .navbar-wrapper .navbar {
    .nav-item  {
      font-family: ATTAleckSansMd;
      font-size: 14px;

      &:hover {
        background: #0057B8;
      }

      &.active{

        &:hover {
          color: white;
        }

        &:before {
          content: none;
        }
      }
    }
  }
}