.add-url-wrapper {
  margin: 32px 0;

  .ant-radio-group {
    flex-direction: column;
  }

  .traffic-collect-msg {
    font-size: 14px;
    color: #BDC2C7;
    margin-bottom: 16px;

    span {
      font-family: ATTAleckSansBd;
      margin-right: 8px;
    }
  }
}
