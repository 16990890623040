.insights-table {
  display: block;

  .table-header-row {
    display: flex;
    align-items: center;
    color: #ece8e4;
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0.15px;
    word-wrap: break-word;
    padding: 7px 0 7px 4px;

    .checkbox {
      padding: 8px;
    }

    .use-case-header {
      padding-left: 16px;
      width: 232px;
    }

    .date-header {
      width: 283px;
    }

    .name-link-header {
      margin-left: 8px;
      cursor: pointer;
      width: 343px;

      a {
        color: var(--primary-blue);
        text-decoration: none;
      }
    }

    .date-range-header {
      width: 342px;
    }
  }

  .table-row {
    display: flex;
    align-items: center;
    box-shadow: 0px -1px 0px 0px rgba(255, 255, 255, 0.12) inset;
    height: 48px;
    color: #FFFFFF;
    padding: 7px 0 7px 4px;
    font-size: 14px;

    .checkbox {
      padding: 8px;
    }

    .severity-column {
      display: flex;
      justify-content: center;
      min-width: 11px;

      .severity {
        width: 10px;
        height: 10px;
        background: #C70032;
        border-radius: 50%;

        &.yellow {
          background: #FFB000;
        }

        &.orange {
          background: #EA712F;
        }
      }
    }

    .use-case-column {
      padding-left: 16px;
      width: 232px;
    }

    .date-column {
      width: 235px;
    }

    .report-name-link-column,
    .name-link-column {
      margin-left: 8px;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      word-wrap: break-word;
      width: 343px;
    }

    .date-range-column {
      width: 342px;
    }

    .type-column {
      text-transform: capitalize;
    }

    .report-name-link-column,
    .raised-column,
    .restored-column {
      color: var(--primary-blue);
      cursor: pointer;
    }

    a {
      color: var(--primary-blue);
      text-decoration: none;
      display: inline-block;
      width: 100%;
      height: 100%;
    }
  }

  .pagination-container {
    display: flex;
    align-items: center;
    color: #ece8e4;
    justify-content: space-between;
    margin: 15px 0;

    .page-size-selector {
      display: flex;
      align-items: center;
      font-size: 12px;

      .page-size-dropdown {
        margin-left: 8px;

        .pagination-select {
          border: none;
          outline: none;
          padding: 8px;

          &:focus,
          & .ant-select-selector {
            box-shadow: none !important;
            outline: none;
          }

          .ant-select-selector, .ant-select-selection-item {
            color: #ece8e4;
            border: none !important;
            background: #212324;
            outline: none;

            &.ant-select-selection-item {
              gap: 3px;
              display: flex;
            }
          }

          .ant-select-arrow {
            margin-left: 5px;

            path {
              fill: #ece8e4;
            }
          }
        }
      }
    }

    .ant-pagination {

      .anticon, .ant-pagination-total-text {
        color: #ece8e4;
      }

      .ant-pagination-item {
        display: none;
      }

      .ant-pagination-item-ellipsis {
        color: #ece8e4;
      }
    }
  }

  .no-result {
    color: #FFFFFF;
    font-size: 20px;
    font-family: ATTAleckSansMd;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 60px 0;
  }
}
