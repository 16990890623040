.trend-chart {
  display: flex;
  height: 116px;
  justify-content: space-between;

  .trend-chart-info {
    display: flex;
    flex-direction: column;
    padding: 20px;
    justify-content: space-between;

    .trend-chart-title {
      font-family: ATTAleckSansMd;
      font-size: 46px;
      color: white;
      line-height: 46px;
    }

    .trend-chart-desc {
      font-size: 18px;
      color: white;
      line-height: 18px;
    }
  }

  .trend-chart-line {
    display: flex;
    flex-direction: column;
    padding-top: 15px;
    align-items: end;
    flex-grow: 1;

    &:has(+ span[aria-live="polite"]) {
      width: 100%;
    }

    span[aria-live="polite"] {
      width: 100%;

      .react-loading-skeleton {
        width: calc(100% - 15px);
        height: 80px;
      }
    }

    .trend-chart-status {
      display: flex;
      gap: 8px;
      align-items: center;
      margin-left: auto;
      margin-right: 20px;

      &.red {
        color: #FF605D;

        svg path {
          fill: #FF605D;
        }
      }

      &.green {
        color: #3DAB3F;

        svg path {
          fill: #3DAB3F;
        }

      }

      .trend-chart-percent {
        display: flex;
        font-size: 16px;
        font-family: ATTAleckSansMd;
        align-items: center;
      }

      .no-change {
        color: #BDC2C7;
      }
    }

  }
}