.anomalies {
  display: flex;
  position: relative;
  margin: -32px 0 -32px -32px;
  min-height: calc(100% + 64px);

  .sidebar-left {
    background-color: #313233;
    width: 240px;
    padding: 16px;
    border-left: 1px solid black;
    border-top: 1px solid black;
    transition: width 0.5s ease;
    align-self: stretch;
    flex: 0 0 auto;

    .navbar-wrapper {
      display: flex;
      padding: 8px 0 8px 0;
      position: fixed;

      .navbar {
        gap: 30px;
      }
    }

    .option-item {
      color: #F8FAFB;
      font-size: 16px;
      font-weight: 400;
      cursor: pointer;
      padding-bottom: 32px;

      &.selected {
        font-weight: 600;
      }
    }

    .collapsed-icon {
      cursor: pointer;
      position: fixed;
      top: calc(50vh + 40px);
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background: #313233;
      border: 1px solid black;
      left: 485px;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 0.5s ease;
    }

    &.collapsed {
      width: 0;

      .navbar-wrapper {
        display: none;
      }

      .collapsed-icon {
        left: 245px;
        transform: rotate(180deg)
      }
    }
  }

  .sidebar-right {
    flex-grow: 1;

    .widgets-header {
      display: flex;
      justify-content: space-between;
      gap: 32px;
      margin-bottom: 24px;
      align-items: center;
      position: relative;

      .anomaly-title {
        color: #F8FAFB;
        font-family: ATTAleckSansBd;
        font-size: 28px;
        font-style: normal;
        font-weight: 700;
        line-height: 36px;
        margin-left: 8px;
      }

      .toggle-switcher {

        label {
          display: flex;
          gap: 16px;

          .toggle-label {
            color: #F8FAFB;
            font-family: ATTAleckSansMd;
          }

          .ant-switch {
            background: #8e979f;

            &.ant-switch-checked {
              background: #1677ff;
            }
          }
        }

      }

      .buttons-container {
        display: flex;
        justify-content: end;
        gap: 16px;
        flex: 1 0 auto;
        align-items: start;

        .reset-and-refresh {
          width: 176px;
          display: flex;
          justify-content: flex-end;

          .reset-btn {
            display: flex;
            width: 40px;
            height: 40px;
            justify-content: center;
            align-items: center;
            gap: 6px;
            flex-shrink: 0;
            border-radius: var(--48, 48px);
            border: 1px solid var(--primary-blue);
            cursor: pointer;

            &:hover {
              background: var(--primary-blue);

              svg {
                animation: refresh-spin 1s linear;
              }

              path {
                fill: #000000;
              }
            }
          }
        }

        .filter-container {
          display: flex;
          gap: 16px;
          border-right: 3px solid #454B52;
          padding-right: 16px;

          .filter-icon {
            .button {
              min-width: 70px;
              width: 70px;
            }
          }

          .apn-name {
            text-transform: none;
          }
        }
      }
    }

    .reset-view {
      top: 0;
      right: 57px;
    }
  }
}

.menu-container.collapsed + .app-permission {
  .anomalies {
    .sidebar-left {
      .collapsed-icon {
        left: 335px;
      }

      &.collapsed {
        .collapsed-icon {
          left: 100px;
        }
      }
    }
  }
}
