.grafana-iframe-container {
  border-radius: 8px;
  background: #212324;
  padding: 8px;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: end;

  .iframe-action-wrapper {
    margin-bottom: -21px;
    display: flex;
    gap: 8px;

    .export-icon {
      z-index: 1;
      cursor: pointer;
      display: flex;

      &:hover {
        svg path {
          fill: #4096ff;
        }
      }
    }

    .expand-icon {
      display: flex;
      flex-direction: row-reverse;
      cursor: pointer;
      width: 16px;
      z-index: 1;
      margin-right: 5px;
      padding-top: 5px;
    }
  }

  .grafana-iframe {
    width: 100%;
    height: 100%;
    border: none;
  }
}

.grafana-overly {
  position: absolute;
  width: calc(100% - 16px);
  height: calc(100% - 16px);
  left: 8px;
  top: 8px;
  background: #000000;

  .full-screen-loader {
    position: absolute;
    width: calc(100% - 16px) !important;
    height: calc(100% - 16px);
    left: 8px;
    top: 8px;
    background: #000000;
  }

  .grafana-error {
    color: white;
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    gap: 16px;

    .error-title {
      display: flex;
      align-items: center;
      gap: 8px;

      span {
        font-size: 16px;
        font-family: ATTAleckSansMd;
      }

      svg {
        margin-top: 2px;
      }
    }

    .error-msg {
      font-size: 14px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
}

.widget-modal {
  .ant-modal-content {
    background-color: #212324;
    border-radius: 8px;

    .grafana-iframe {
      width: 100%;
      height: 100%;
      border: none;
    }
  }

  .ant-modal-close {
    color: white;
    margin-right: -10px;

    &:hover {
      color: white;
    }
  }
}
