.version-history-container {

  .history-title {
    color: #F8FAFB;
    font-family: ATTAleckSansBd;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
  }

  .history-subtitle {
    color: #F8FAFB;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }

  .download-container {
    display: flex;
    justify-content: space-between;
  }

  .link {
    cursor: pointer;
    color: var(--primary-blue);
  }

  .history-content {
    color: #878C94;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }

  .separator {
    width: 264px;
    height: 1px;
    background: #454B52;
    margin: 24px 0 24px 0
  }
}
