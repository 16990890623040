.unauthorized-destination {
  display: flex;
  flex-direction: column;

  .no-apn-selected {
    font-size: 15px;
    color: white;
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 24px;
    align-items: center;
    width: 100%;
    background: #353739;
    padding: 100px 0;
    border-radius: 18px;
    font-family: 'ATTAleckSans'
  }


  .unauthorized-item {
    display: flex;
    flex-direction: column;
    padding-top: 32px;

    &:first-child {
      padding-top: 16px;
    }

    .unauthorized-item-header {
      margin-bottom: 16px;
      gap: 12px;
      display: flex;
      flex-direction: column;

      .apn-name {
        font-family: ATTAleckSansBd;
        font-size: 19px;
        color: #F8FAFB;
      }

      .fields-wrapper {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        gap: 24px;
      }

      .unauthorized-action {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 16px;
        justify-content: space-between;

        .right-actions {
          display: flex;
          align-items: center;
          gap: 18px;

          .percentage-display {
            padding: 0 27px;
            height: 40px;
            gap: 6px;
            border-radius: 20px;
            display: flex;
            background: #353739;
            color: #F3F4F6;
            align-items: center;
            width: fit-content;
            margin-left: auto;
            font-size: 18px;
            position: relative;
            min-width: 160px;

            span {
              font-family: ATTAleckSansBd;
            }

            .full-screen-loader {
              position: absolute;
              background: transparent;
              zoom: 0.4;
              justify-content: end;
              left: 43%;
              width: 33%;
            }
          }

          .remove-apn {
            cursor: pointer;
          }

          .export-icon {
            cursor: pointer;
            border-radius: 32px;
            border: 1px solid var(--primary-blue);
            height: 38px;
            width: 38px;
            display: flex;
            align-items: center;
            justify-content: center;

            &:hover {
              border-color: #4096ff;

              svg path{
                fill: #4096ff;
              }
            }
          }
        }
      }
    }

    .destination-table {
      display: flex;
      flex-direction: column;
      color: #F8FAFB;

      .collecting-data {
        font-family: ATTAleckSansMd;
        padding: 16px;
        border-bottom: 1px solid #454B52;
      }

      .destination-header {
        display: flex;
        align-items: center;
        height: 48px;
        padding: 0 16px;
        background: #353739;

        .dest-header-cell {
          font-family: ATTAleckSansMd;
          font-size: 12px;

          &:first-child {
            flex-basis: 20%;
          }
        }
      }

      .dest-table-content {
        display: flex;
        flex-direction: column;

        .dest-row {
          display: flex;
          border-bottom: 1px solid #454B52;
          height: 48px;
          align-items: center;
          padding: 0 16px;

          .dest-cell {
            font-size: 14px;

            &:first-child {
              flex-basis: 20%;
            }
          }

          .delete-cell {
            display: none;
            cursor: pointer;
            margin-left: auto;
          }

          &:hover .delete-cell{
            display: block;
          }
        }
      }
    }
  }
}

.ant-tooltip.percent-warning {
  .ant-tooltip-inner {
    width: 310px;
  }
}