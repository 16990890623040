.use-case-management {
    padding: 0 32px 0 16px;
    display: flex;

  .sidebar-left {
    border-right: 1px solid #454B52;
    width: 240px;
    padding: 16px 0;

    .option-item {
      color: #F8FAFB;
      font-size: 16px;
      font-weight: 400;
      cursor: pointer;
      padding-bottom: 32px;

      &.selected {
        font-weight: 600;
      }
    }
  }

  .sidebar-right {
    padding: 32px;
    flex-grow: 1;
  }
}