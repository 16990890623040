.tooltip {
  position: absolute;
  text-align: left;
  width: auto;
  height: auto;
  padding: 8px 11px;
  background: black;
  border: 0;
  border-radius: 9px;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.2s;
  color: white;

  .tooltip-title {
    display: flex;
    gap: 6px;
    font-family: ATTAleckSansMd;
    font-size: 14px;
    text-transform: capitalize;
    align-items: center;

    .tooltip-title-count {
      font-family: ATTAleckSansBd;
    }
    margin-bottom: 4px;
  }

  .tooltip-devices {
    font-size: 11px;
    font-family: ATTAleckSansMd;
    margin-bottom: 4px;
  }

  .tooltip-date {
    font-size: 11px;
  }
}