.exception-list-container {
  padding: 32px;
  height: calc(100vh - 310px);
  overflow: auto;

  .instructions {
    color: #F8FAFB;
    font-family: ATTAleckSansMd;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 1.5;
    margin-bottom: 32px;
    margin-top: 5px;
  }

  .exception-item {

    span {
      color: #F8FAFB;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
    }

    .ant-checkbox-inner:after {
      border-color: #F8FAFB;
    }
  }

  .exception-buttons-container {
    display: flex;
    gap: 16px;
    margin-top: 39px;

    .upload {
      width: 140px;
    }

    .version-history {
      width: 171px;
    }
  }

  .ant-checkbox-inner {
    background-color: transparent !important;
  }

  .ant-checkbox-disabled .ant-checkbox-inner {
    background-color: #454B52 !important;
  }

  .sidebar.open {
    overflow: auto;
  }

  .ant-radio-group {
    flex-direction: column;
  }
}
