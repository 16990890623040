.button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  width: auto;
  padding-left: 24px;
  padding-right: 24px;
  height: 40px;
  background: var(--primary-blue);
  border-radius: 48px;
  cursor: pointer;
  font-family: ATTAleckSansMd;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: -0.3px;
  color: #000000;
  pointer-events: auto;
  min-width: 106px;

  .loader {
    width: 24px;
    height: 24px;
    border: 2.5px solid rgba(255, 255, 255, 0.2);
    border-top-color: white;
    border-radius: 50%;
    animation: spin 1s linear infinite;
    transition: opacity 0.3s;
  }

  &.disabled {
    pointer-events: none;
    color: #BDC2C7;
    background: #686E74;
  }

  .btn-img {
    display: flex;

    path {
      fill: #000000;
    }

    &.disabled {
      path {
        fill: white;
      }
    }
  }
}

@keyframes spin {
  0% { transform: rotate(0deg);}
  100% { transform: rotate(360deg);}
}
