.custom-modal {
  .url-ip-fields-wrapper {
    display: flex;
    gap: 20px;

    .select-label {
      text-transform: capitalize;
    }

    .textarea-wrapper:nth-child(1) {
      flex: 1;
    }

    .textarea-wrapper:nth-child(1) {
      flex-basis: 30%;
    }

    .ant-input {
      border-radius: 8px;
      border: 1px solid #DCDFE3;
      background: transparent;
      color: #F8FAFB;
      padding: 12px;
      font-family: ATTAleckSans;

      &::placeholder {
        color: #BDC2C7;
      }

    }
  }
}