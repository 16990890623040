.sub-menu {
  display: flex;
  gap: 24px;

  .sub-menu-item {
    display: flex;
    flex-direction: column;

    .sub-menu-link {
      text-decoration: none;
      cursor: pointer;
      color: #F8FAFB;
      font-size: 16px;
      font-weight: 500;
      height: 30px;

      &.selected {
        font-weight: 700;
        position: relative;

        &:after {
          width: 100%;
          height: 1px;
          background: var(--primary-blue);
          bottom: 0;
          right: 0;
          content: "";
          position: absolute;
        }
      }
    }
  }
}
