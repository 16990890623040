.recommended-action-item {
  border-bottom: 1px solid #31353D;
  padding: 8px 18px 15px 18px;

  &:last-child {
    border-bottom: none;
  }

  .item-header {
    display: flex;
    align-items: center;
    position: relative;

    .recommended-status-indicator {
      width: 12px;
      height: 12px;
      border-radius: 50%;
      position: absolute;
      left: -17px;
    }

    .title {
      font-family: ATTAleckSansMd;
      font-size: 14px;
    }
  }

  .item-details {
    margin-top: 6px;
    font-size: 11px;

    .source {
      margin-bottom: 4px;

      span {
        font-family: ATTAleckSansBd;
        margin-right: 4px;
      }
    }
  }
}