.select-dest-modal {

  .modal-title {
    text-transform: capitalize;
  }

  .ant-modal-content .ant-modal-body .info {
    margin: 12px 0 24px 0;
  }

  .select-dest-modal-header {
    display: flex;
    align-items: center;
    gap: 16px;

    .select-label {
      margin: 0;
    }

    .ant-select-single {
      width: 300px;
    }

    .ant-radio-group {
      flex-direction: row;
      margin: 16px;
    }
  }

  .select-dest-modal-content {
    margin-top: 16px;

    .no-apn-selected {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      background: #353739;
      border-radius: 18px;
      height: 305px;
      gap: 16px;
      justify-content: center;

      .no-apn-text {
        width: 480px;
        font-size: 15px;
        line-height: 22px;
        color: white;
      }
    }
  }

  .ant-modal-footer {
    .ant-btn-primary {
      text-transform: capitalize;
    }
  }
}

.custom-modal .ant-modal-content .ant-modal-body .select-dest-modal .select-label {
  margin: 0;
}
