.speedometer-widget {
  display: flex;
  flex-direction: column;
  padding: 20px 18px;

  .speedometer-title {
    color: white;
    font-size: 16px;
    font-family: ATTAleckSansMd;
  }

  .speedometer-status {
    text-transform: capitalize;
    font-size: 26px;
    font-family: ATTAleckSansMd;
    padding: 4px 0 10px 0;

  }

  .speedometer-chart {
    background: url('../../../assets/svg/speedometer.svg') no-repeat;
    margin: 0 auto;
    width: 170px;
  }

  &.low {
    path {
      stroke: #848AFF;
    }
    .speedometer-status {
      color: #848AFF;
    }
  }

  &.medium {
    path {
      stroke: #EA712F;
    }

    .speedometer-status {
      color:#EA712F;
    }

    .speedometer-chart {
      background-position: 0 7px;
    }
  }

  &.high {
    path {
      stroke: #FF5D65;
    }
    .speedometer-status {
      color: #FF5D65;
    }

    .speedometer-chart {
      background-position: 0 10px;
    }
  }
}
