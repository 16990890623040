.date-range-picker-container {
  position: relative;

  .selection-date {
    cursor: pointer;
    color: #F8FAFB;
    font-size: 15px;
    border-radius: 4px;
    border: 1px solid #878C94;
    padding: 0 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40px;
    background: #212324;
  }

  .date-range-picker-popup {
    visibility: hidden;
    position: absolute;
    border: 1px solid #686E74;
    background: #212324;
    border-radius: 8px;
    top: 50px;
    z-index: 1000;
    opacity: 0;
    height: 0;
    transition: all .2s ease;
    color: #FFFFFF;
    flex-direction: column;

    &.is-visible {
      visibility: visible;
      display: flex;
      height: 455px;
      opacity: 1;
    }

    .content-wrapper {
      display: flex;

      .close-options {
        color: #F8FAFB;
        display: flex;
        flex-direction: column;
        gap: 16px;
        font-size: 14px;
        padding: 24px 32px;
        white-space: nowrap;
        width: 95px;

        .date-option {
          cursor: pointer;

          &.active {
            font-family: ATTAleckSansBd;
          }
        }
      }

      .date-range-picker {
        display: flex;
        align-items: center;
        flex-direction: column;

        .rdp-day_selected {
          background-color: var(--primary-blue);
        }

        .rdp-button:hover:not([disabled]):not(.rdp-day_selected) {
          color: black;
        }

        .range-apply-btn {
          width: 150px;
        }
      }
    }

    .info-wrapper {
      margin: 24px 0 0 32px;
      display: flex;
      gap: 10px;
      align-items: center;

      span {
        font-size: 14px;
      }

      svg path {
        fill: white;
      }
    }
  }

  .right-aligned {
    right: 0;
  }

  .left-aligned {
    left: 0;
  }
}
