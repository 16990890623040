.recommended-action-list {
  display: flex;
  flex-direction: column;
  padding: 24px;
  color: white;

  .recommended-action-title {
    font-size: 20px;
    font-family: ATTAleckSansMd;
    margin-bottom: 8px;
  }

  .security-view-all {
    margin-bottom: 4px;
  }
}