.traffic-class-rules-table {
  display: flex;
  flex-direction: column;
  color: #F8FAFB;
  flex: 1;

  .rules-table-header {
    display: flex;
    align-items: center;
    height: 32px;
    padding: 0 16px;
    background: #353739;
    border-radius: 10px 10px 0 0;

    .rule-header-cell {
      font-family: ATTAleckSansMd;
      font-size: 12px;
      flex-basis: 40%;
    }
  }

  .rules-table-content {
    display: flex;
    flex-direction: column;
    max-height: 200px;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 8px;
      height: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #888;
      border-radius: 10px;
      border: 2px solid #353739;
    }

    &::-webkit-scrollbar-track {
      background: #353739;
    }

    scrollbar-width: thin;
    scrollbar-color: #888 #353739;

    .rule-row {
      display: flex;
      border-bottom: 1px solid #454B52;
      height: 48px;
      align-items: center;
      padding: 0 16px;
      flex-shrink: 0;

      .rule-cell {
        font-size: 14px;
        flex-basis: 40%;

        &:first-child {

        }
      }

      .action-cell {
        margin-left: auto;
        display: flex;
        gap: 20px;
      }

      .delete-cell, .edit-cell {
        display: none;
        cursor: pointer;
        background: none;
        border: none;
        padding: 0;
      }

      &:hover .delete-cell,
      &:hover .edit-cell {
        display: block;
      }
    }
  }

  .drag-header,
  .drag-handle-icon {
    flex-basis: 20px;
    font-weight: bold;
    padding-right: 20px;
  }

  .no-rules {
    background-color: #31353D80;
    border-radius: 10px;
    padding: 40px 0;
    text-align: center;
    font-size: 14px;
    color: #FFFFFF;
  }
}