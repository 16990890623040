.custom-modal.add-edit-class {
  .class-name-input {
    border-radius: 8px;
    border: 1px solid #DCDFE3;
    background: transparent;
    color: #F8FAFB;
    padding: 12px;
    font-family: ATTAleckSans;

    &::placeholder {
      color: #BDC2C7;
    }

  }
}