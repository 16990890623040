.issue-management-page {
  padding-top: 44px;
  margin: 0 auto;
  width: 1280px;

  .issue-management-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 28px;
    margin-top: 12px;

    .title {
      font-family: ATTAleckSansBd;
      font-size: 40px;
      line-height: 48px;
      color: white;
    }

    .issue-management-actions {
      display: flex;
      gap: 16px;

      .filter-button {
        background: transparent;
        color: white;
        font-size: 14px;
        cursor: pointer;
        display: flex;
        align-items: center;
        width: 216px;
        height:40px;
        padding: 11px 14px;
        border-radius: 44px;
        border: 1px solid #DCDFE3;
        justify-content: space-between;
      }
    }
  }

  .issue-management-table {
    display: flex;
    flex-direction: column;
    width: 100%;
    color: white;
    background: #1D2329;
    border-radius: 16px;

    .table-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: #31353D;
      height: 31px;
      border-radius: 10px 10px 0 0;
      padding: 0 20px;

      .title {
        font-size: 16px;
        font-family: ATTAleckSansBd;
      }

      .header-cell {
        cursor: pointer;
        font-size: 12px;
        font-family: ATTAleckSansMd;
        flex: 1;
        display: flex;
        align-items: center;
      }

      .sort-icon {
        margin-left: 5px;
        transform: rotate(0deg);
        transition: transform 0.3s ease;

        &.desc {
          transform: rotate(180deg);
        }
      }
    }

    .skeleton-row {
      align-items: center;
      justify-content: space-between;

      .skeleton-cell-wrap {
        flex: 1 1;

        .skeleton-cell {
          width: 100px;
        }
      }
    }

    .row {
      display: flex;
      cursor: pointer;
      border-bottom: 1px solid #31353D;
      height: 40px;
      padding: 0 18px;

      &.active {
        background: #0049DC;
      }

      .cell {
        flex: 1;
        display: flex;
        align-items: center;
        font-size: 14px;
        font-family: ATTAleckSansMd;

        &.devices {
          gap: 8px;
        }

        .importance-wrapper {
          width: 77px;

          .importance {
            font-size: 12px;
            font-family: ATTAleckSansBd;
            padding: 4px 10px;
            border: 1px solid;
            border-radius: 12px;
            text-transform: capitalize;
            width: fit-content;
          }
        }
      }
    }

    .pagination {
      display: flex;
      justify-content: end;
      gap: 14px;
      margin: 28px 36px;

      .pagination-number {
        display: flex;
        justify-content: end;
        gap: 8px;

        button {
          cursor: pointer;
          background: transparent;
          border: none;
          color: white;
          font-size: 14px;
          font-family: ATTAleckSansMd;
          height: 24px;

          &.active {
            color: #009FDB;
            position: relative;

            &:after {
              position: absolute;
              content: "";
              height: 2px;
              background: linear-gradient(90deg, #0079B1 0%, #009FDB 50%, #00C9FF 100%);
              bottom: 0;
              width: 100%;
              right: 0;
            }
          }
        }
      }

      .prev-next-btn {
        cursor: pointer;
        background: transparent;
        border: none;
        color: #009FDB;
        display: flex;
        align-items: center;
        gap: 10px;
        font-size: 14px;
        font-family: ATTAleckSansMd;

        &:last-child {
          svg {
            rotate: 180deg;
          }
        }
      }

      button:disabled {
        cursor: not-allowed;
      }
    }

    .empty-state {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 20px;
      margin-top: 10%;

      .empty-state-title {
        font-size: 20px;
        font-family: ATTAleckSansMd;
      }

      .empty-state-desc {
        font-size: 16px;
        font-family: ATTAleckSansMd;
      }
    }
  }

  .footer {
    margin: 72px 0;
  }
}

.filter-popup {
  background: #000000;
  box-shadow: 0 1px 4px 0 #25303A1A;
  border: 1px solid #686E74;
  padding: 15px;
  color: white;
  width: 216px;

  .ant-popover-inner {
    background: none;
    color: white;
    padding: 0;

    .filter-group-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 8px;

      .filter-group-title {
        color: white;
        font-size: 14px;
        font-family: ATTAleckSansMd;
      }

      .any-button {
        border: none;
        background: none;
        color: #009FDB;
        font-size: 14px;
        font-family: ATTAleckSansMd;
        height: auto;
        padding: 0;
      }
    }

    .ant-checkbox-group {
      display: flex;
      flex-direction: column;

      label {
        color: white;
        text-transform: capitalize;
        font-size: 14px;
        display: flex;
        flex-direction: row-reverse;
        width: 100%;
        justify-content: space-between;
        height: 40px;
        align-items: center;

        .ant-checkbox .ant-checkbox-inner {
          width: 24px;
          height: 24px;

          &:after {
            width: 6.6px;
            height: 14.4px;
            border-color: white;
          }
        }

        .ant-checkbox+span {
          width: 100%;
        }
      }
    }
  }
}