.app-permission {
  display: flex;
  height: 100%;
  width: 100%;

  &.unauthorized {
    .exception-buttons-container,
    .use-case-management:not(.destinations-edit-allowed .unauthorized_destination) {

      .number-select-wrapper,
      .toggle,
      input,
      .ant-input-number,
      button.add-apn-btn,
      button.unauthorized-switch,
      button.add-url-btn,
      button.add-destinations,
      button.save,
      button.upload {
        pointer-events: none !important;
        border-color: #686E74 !important;
        color: #878C94 !important;

        &.ant-btn-primary {
          background: #686E74 !important;
        }
      }

      .ant-switch {
        background: #BDC2C7 !important;

        &.ant-switch-checked {
          background: #BDC2C7 !important;
        }
      }

      .ant-switch.ant-switch-checked .ant-switch-handle::before {
        background: #FFFFFF !important;
      }

      .remove-apn,
      .dest-row:hover .delete-cell,
      .row:hover .delete {
        display: none !important;
      }
    }
  }
}
