.insights {
  .title-container {
    .insights-filter-container {
      display: flex;
      gap: 16px;

      .filter-icon {
        .button {
          padding: 0 11px;
          min-width: 0;
        }
      }
    }
  }
}

.insights-container {
  padding: 24px;
  position: relative;
  z-index: 0;

  .title {
    color: #F8FAFB;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;

    .title-summary {
      font-weight: 400;
    }
  }

  .activity-widget,
  .traffic-classes-widget {
    position: relative;
    height: calc(100vh - 315px);
  }
}
