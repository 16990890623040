.security-dashboard {

  .security-dashboard-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 28px;

    .security-dashboard-header-title {
      color: white;
      font-family: ATTAleckSansBd;
      font-size: 40px;
    }

    .security-dashboard-header-action {
      display: flex;
      gap: 10px;
      align-items: center;

      .security-dashboard-date-filter {
      }

      .security-dashboard-refresh {
        background: transparent;
        border: 1px solid #DCDFE3;
        border-radius: 48px;
        height: 40px;
        width: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        svg {
          transition: transform 1s ease-in-out;

        }

        &:hover, &:active {
          svg {
            transform: rotate(360deg);
          }
        }
      }
    }
  }

  .security-dashboard-content {
    display: flex;
    gap: 20px;
    flex-direction: column;

    .row {
      display: flex;
      gap: 14px;

      .box {
        background: #1D2329;
        width: 100%;
        height: 100%;
        border-radius: 16px;
      }
    }

    .security-dashboard-top {
      height: 116px;
    }
    .security-dashboard-middle {
      height: 375px;

      .box:first-child {
        width: 800px;
      }

      .box:last-child {
        flex-grow: 1;
      }
    }
    .security-dashboard-bottom {
      height: 940px;
    }
    .security-dashboard-help-area {
      gap: 27px;
      margin-top: 10px;
    }
  }

  .security-dashboard-footer {
    padding: 70px 0;
  }

  .security-view-all {
    font-family: ATTAleckSansMd;
    font-size: 14px;
    color: #009FDB;
    cursor: pointer;
    text-decoration: none;
  }
}
