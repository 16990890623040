.status-indicator {
  padding: 0 16px;
  border-radius: 16px;
  color: #FFFFFF;
  font-size: 14px;
  height: 30px;
  display: flex;
  align-items: center;

  &.inactive {
    background-color: #989898;
  }
}