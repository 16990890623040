.trend-arrow {
  &.up {
    path {
      fill: #FF605D;
    }
  }

  &.down {
    path {
      fill: #3DAB3F;
    }
  }

  &.none {
    path {
      fill: #BDC2C7;
    }
  }
}