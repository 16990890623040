@font-face {
    font-family: ATTAleckSans;
    src: url("ATTAleckSans_Rg.ttf") format("truetype");
}

@font-face {
    font-family: ATTAleckSansMd;
    src: url("ATTAleckSans_Md.ttf") format("truetype");
}

@font-face {
    font-family: ATTAleckSansBd;
    src: url("ATTAleckSans_Bd.ttf") format("truetype");
}
