.insight-item {

  .single-item-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;

    .title-container {
      display: flex;
      align-items: center;

      .back-arrow {
        cursor: pointer;
      }

      .title {
        color: #F8FAFB;
        font-size: 28px;
        font-weight: 700;
        line-height: 36px;
        word-wrap: break-word;
        margin: 0;
        padding-left: 8px;
      }
    }
  }

  .html-content {
    width: 100%;
    border-radius: 8px;
    padding: 24px;
    box-sizing: border-box;

    img {
      width: 100%;
    }

    #main-body {
      background-color: #FFFFFF;
    }
  }
}
