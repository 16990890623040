.custom-modal {

  .ant-modal-content {
    padding: 32px;
    background: #2F3132;
    border-radius: 32px;

    .ant-modal-close {
      display: flex;
      align-items: center;
      top: 32px;
      right: 32px;

      .ant-modal-close-x {
        display: flex;
        align-items: center;

        svg path{
          stroke: #DCDFE3;
        }
      }
    }

    .ant-modal-body {
      .modal-title {
        font-size: 26px;
        font-family: ATTAleckSansBd;
        color: #F3F4F6;
      }

      .info {
        margin: 24px 0;
        font-size: 16px;
        line-height: 18px;
        color: #F8FAFB;

        .text {
          display: flex;
          flex-direction: column;
          gap: 8px;
          line-height: 24px;
        }

        .select-label {
          color: #F3F4F6;
          font-family: ATTAleckSansBd;
          font-size: 14px;
          margin-bottom: 8px;
        }

        .ant-select-selection-placeholder, .ant-select-selection-item-content {
          font-size: 14px;
          line-height: 18px;
          display: flex;
          align-items: center;
        }

        .textarea-wrapper {
          margin-bottom: 20px;

          .textarea-input {
            border-radius: 8px;
            border: 1px solid #DCDFE3;
            background: transparent;
            color: #F8FAFB;
            padding: 12px;
            font-family: ATTAleckSans;

            &::placeholder {
              color: #BDC2C7;
            }
          }
        }


        .error-message {
          color: #C70032;
          font-size: 14px;
          margin-top: 10px;
        }

        .explain-message {
          color: #BDC2C7;
          font-size: 12px;
          padding-top: 5px;
        }

        .ant-select {
          background: transparent;

          .ant-select-selector {
            background: transparent;
            border-radius: 8px;
            border: 1px solid #DCDFE3;
            color: #BDC2C7;

            .ant-select-selection-item {
              background: rgba(0, 0 , 0, 0.2);
            }

            .ant-select-selection-item-remove {
              color: #BDC2C7;
            }

            .ant-select-selection-placeholder {
              font-size: 16px;
              color: #BDC2C7;
              padding: 12px 0;
            }
          }

          .ant-select-arrow {
            color: #BDC2C7;
          }

          &.ant-select-single {
            .ant-select-selector .ant-select-selection-item {
              color: #BDC2C7;
              background: transparent;
            }
          }
        }
      }
    }

    .ant-modal-footer {
      .ant-btn + .ant-btn:not(.ant-dropdown-trigger) {
        margin-inline-start: 16px;
      }
    }
  }
}