.navbar-wrapper {

  .navbar {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .nav-item {
      color: #F8FAFB;
      font-family: ATTAleckSans;
      font-size: 16px;
      cursor: pointer;
      line-height: 32px;
      padding: 0 16px;
      text-decoration: none;

      &.active {
        font-family: ATTAleckSansBd;
        color: var(--primary-blue);
        position: relative;

        &:before {
          content: "";
          position: absolute;
          margin-top: 2px;
          left: 5px;
          width: 4px;
          height: 24px;
          flex-shrink: 0;
          border-radius: 1px;
          background: #43A5F8;
        }
      }

      &.disabled {
        color: #8e979f;
        cursor: default;
        pointer-events: none;
      }
    }
  }

}
