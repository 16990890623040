.security-issue-importance {
  padding: 20px;
  color: white;

  .donut-chart-loader {
    width: 280px;
    height: 280px;
  }

  .count-items-loader {
    height: 28px;
    width: 150px;
  }

  .security-importance-title {
    font-size: 20px;
    font-family: ATTAleckSansMd;
    margin-bottom: 30px;
  }

  .security-importance-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .security-legend {
      display: flex;
      flex-direction: column;
      gap: 16px;
      margin-top: 32px;

      .security-legend-title {
        font-size: 16px;
      }

      .security-legend-items {
        display: flex;
        flex-direction: column;
        gap: 16px;

        .legend-item {
          display: flex;
          gap: 13px;
          align-items: center;
          height: 28px;

          .legend-color {
            width: 4px;
            border-radius: 100px;
            height: 100%;
          }

          .legend-count {
            font-size: 28px;
            font-family: ATTAleckSansMd;
          }

          .legend-text {
            font-size: 14px;
            text-transform: capitalize;
          }
        }
      }
    }

    .issue-details {
      display: flex;
      flex-direction: column;
      gap: 6px;

      .issue-count-msg {
        display: flex;
        gap: 6px;
        align-items: center;

        .issue-count-circle {
          height: 12px;
          width: 12px;
          border-radius: 50%;
        }

        span {
          font-size: 14px;
          font-family: ATTAleckSansMd;
        }
      }

      .issue-source-msg {
        font-size: 11px;

        span {
          font-family: ATTAleckSansBd;
          padding-right: 3px;
        }
      }

      .issue-datetime-msg {
        font-size: 11px;
      }
    }

  }

  .security-chart {
    .no-result {
      height: 200px;
      width: 200px;
      border: 40px solid #272C33;
      color: white;
      font-size: 18px;
      text-align: center;
      border-radius: 50%;
      justify-content: center;
      display: flex;
      align-items: center;
    }
  }
}
