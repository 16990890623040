.use-case {

  .use-case-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
    margin-bottom: 16px;

    .use-cases-sub-title {
      line-height: 28px;
      display: flex;
      align-self: center;
      gap: 24px;

      span {
        display: flex;
        align-self: center;
        font-size: 20px;
        color: #F8FAFB;
        font-family: ATTAleckSansBd;
      }
    }

    .action-wrapper {
      display: flex;
      gap: 24px;
    }
  }

  .no-table {
    color:  #878C94;
    text-align: center;
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    padding: 100px 0;
  }

  .fields-wrapper, .table-wrapper {
    .input-wrapper {
      display: flex;
      align-items: center;
      color: #F8FAFB;
      font-family: ATTAleckSans;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;

      .input-label {
        text-align: right;
        flex: 1;
      }

      .ant-input-number {
        margin-right: 16px;
        margin-left: 24px;
        background: none;
        border: 1px solid #878C94;

        input {
          color: #F8FAFB;
          font-size: 14px;
        }
      }

      .input-unit {
        text-align: left;
        min-width: 60px;
      }
    }
  }
}
