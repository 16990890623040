.anomalies-filter {
  display: flex;
  flex-direction: column;
  color: #F8FAFB;
  font-style: normal;

  .filter-title {
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
    margin-bottom: 16px;
  }

  .filter-subtitle {
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 8px;
    margin-top: 24px;
  }

  .filter-date-range,
  .apn-select:has(+ .filter-subtitle){
    margin-bottom: 24px;
  }

  .anomalies-filter-list {

    .ant-select-selector {
      border-radius: 8px;
      border: 1px solid #686E74;
      background: #212324;
      color: white;
      padding: 0 14px;
      font-size: 14px;
      line-height: 18px;

      .ant-select-selection-item {
        color: white;
      }

      .ant-select-selection-placeholder {
        color: white;
      }

      .ant-select-item-option-content {
        color: #494c4d;
      }
    }

    .ant-select-arrow {
      background: no-repeat center url('./../../../../assets/svg/chevron-down.svg');
    }
  }
}

.apply-btn {
  margin-top: 24px;
}
