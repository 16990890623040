.header {
  height: 80px;
  background: #000000;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 22px;
  border-bottom: 1px solid #B8C0CC4D;

  .left {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 15px;

    .hamburger {
      cursor: pointer;
    }

    .title {
      font-size: 24px;
      font-family: ATTAleckSansMd;
      line-height: 32px;
      color: white;
      white-space: nowrap;
    }
  }


  .right {
    display: flex;
    gap: 32px;

    .user {
      display: flex;

      .icon {
        display: flex;
        align-items: center;
        margin-right: 24px;
      }

      .user-circle {
        color: white;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        background: #0057B8;
        border-radius: 100px;
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        align-self: center;
        text-align: center;
        justify-content: center;
      }
    }

    .customer-list-btn {
      width: 200px;
      min-width: 200px;

      .ant-select-arrow {
        background: no-repeat center url('./../../assets/svg/chevron-down.svg');
      }
    }

    .single-customer-label {
      color: white;
    }
  }
}
