.traffic-classes {
  padding: 24px 40px;

  .traffic-classes-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
    margin-bottom: 32px;

    .traffic-classes-sub-title {
      line-height: 28px;
      display: flex;
      align-self: center;
      gap: 24px;

      span {
        display: flex;
        align-self: center;
        font-size: 26px;
        color: #F8FAFB;
        font-family: ATTAleckSansBd;
      }
    }

    .action-wrapper {
      display: flex;
      gap: 24px;
      align-items: center;

      .collapse-all-button {
        color: #43A5F8;
        padding: 0;
        background: transparent;
        border: none;
      }
    }
  }

  .traffic-classes-content {
    display: flex;
    flex-direction: column;

    .traffic-class-group {
      padding: 24px;
      border-top: 1px solid #3F4449;

      .traffic-class-header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .traffic-class-name {
          color: #F8FAFB;
          font-size: 18px;
          font-family: ATTAleckSansBd;
          display: flex;
          gap: 15px;
          align-items: center;
          position: relative;

          .traffic-class-icon {
            width: 36px;
            text-align: center;
          }

          .drag-handle-icon {
            position: absolute;
            left: -45px;
            font-weight: bold;
          }

          .collapse-icon {
            width: 33px;
            height: 33px;
          }
        }

        .delete-class, .edit-class {
          background: none;
          border: none;
          padding: 0;
        }

        .edit-class {
          margin-left: 10px;
        }

        .traffic-class-actions-wrapper {
          display: flex;
          gap: 10px;
          align-items: center;

          .traffic-class-actions {
            display: flex;
            gap: 10px;
          }

          .collapse-icon {
            height: 33px;
            padding: 0;
            background: transparent;
            border: none;
          }
        }
      }

      .traffic-class-content {
        display: flex;
        margin-top: 15px;
        gap: 30px;
        overflow: hidden;
        height: 0;
        visibility: hidden;
        transition: all 0.5s ease;
        transform-origin: top;
        opacity: 0;

        .traffic-class-description {
          flex-basis: 30%;
          color: #FFFFFF;
          margin-top: 15px;
          word-break: break-word;
          white-space: pre-wrap;
        }
      }

      &.collapsed {
        .traffic-class-actions,
        .traffic-class-content {
          height: 0;
          opacity: 0;
          visibility: hidden;
          transform: translateY(-50px);
          padding: 0;
          margin: 0;
        }

        .collapse-icon {
          transform: rotate(0deg);
          transition: transform 0.5s ease;
        }
      }

      &.expanded {
        .traffic-class-actions,
        .traffic-class-content {
          height: 100%;
          opacity: 1;
          visibility: visible;
          transform: translateY(0);
        }

        .collapse-icon {
          transform: rotate(180deg);
          transition: transform 0.5s ease;
        }
      }

    }
  }
}