.dashboard {
  position: relative;

  .dashboard-header {
    display: flex;
    justify-content: space-between;

    .date-range-filter {
      width: 286px;
      margin-left: 11px;
    }

    .reset-btn {
      margin-right: 10px;
      display: flex;
      width: 40px;
      height: 40px;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
      border-radius: var(--48, 48px);
      border: 1px solid var(--primary-blue);
      cursor: pointer;

      &:hover {
        background: var(--primary-blue);

        svg {
          animation: refresh-spin 1s linear;
        }

        path {
          fill: #000000;
        }
      }
    }
  }

  .widgets-container {
    .reset-view {
      top: 0;
    }

    .buttons-container {
      margin-bottom: 10px;
    }
  }

  .wide-section {
    border-radius: 12px;
    background: #212324;
    padding: 24px;
  }

  .overall-volume {
    height: 453px;
    margin-top: 24px;
  }

  .dashboard-alerts {
    height: 415px;
    margin-left: 10px;
    margin-right: 10px;

    .dashboard-alerts-title {
      color: #F8FAFB;
      font-family: ATTAleckSansMd;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;

      .title-summary {
        font-weight: 400;
      }
    }

    .dashboard-alerts-content {
      margin-top: 24px;
      margin-bottom: 19px;

      .no-data {
        height: 340px;
        color: #F8FAFB;
        font-size: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: ATTAleckSansBd;
      }
    }

    .view-all {
      color: var(--primary-blue);
      font-family: ATTAleckSansMd;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      text-decoration: none;
      padding-left: 12px;
    }
  }
}
