.error-container {
  background: black;
  height: 100vh;

  .header {
    height: 80px;
    background: #212324;

    .logo {
      display: flex;
      align-items: center;
      gap: 10px;

      .title {
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 32px;
        color: white;
      }

      svg {
        width: 39px;
        height: 39px;
        padding-bottom: 6px;
      }
    }
  }

  .error-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 100px auto;
    background: #212324;
    width: 480px;
    padding: 64px;
    border-radius: 16px;
    justify-content: center;

    .padlock-frame {
      padding: 8px;
      border-radius: 12px;
      border: 1px solid #2F3132;
      margin-bottom: 40px;

      .inner-frame {
        display: flex;
        padding: 8px;
        border-radius: 8px;
        background: #2F3132;

        path:nth-child(1) {
          fill: #FFFFFF;
        }
      }
    }

    .message {
      font-style: normal;
      text-align: center;
      font-feature-settings: 'clig' off, 'liga' off;
      margin-bottom: 32px;
      width: 384px;
    }

    .message-title {
      color: #FFFFFF;
      font-family: ATTAleckSansBd;
      font-size: 26px;
      font-weight: 700;
      line-height: 36.4px;
      letter-spacing: -0.78px;
    }

    .message-subtitle{
      color: #DCDFE3;
      font-family: ATTAleckSans;
      font-size: 18px;
      font-weight: 400;
      line-height: 28.8px;
      letter-spacing: -0.54px;
    }
  }
}
