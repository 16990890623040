.sidebar {
  position: fixed;
  top: 0;
  right: 0;
  width: 324px;
  height: calc(100vh - 48px); // minus header and minus padding top + bottom
  padding: 24px;
  background-color: black;
  box-shadow: 0 12px 48px 12px black;
  z-index: 1;
  transform: translateX(100%);
  transition: transform 0.5s ease-in-out;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &.open {
    transform: translateX(0);
  }
}
