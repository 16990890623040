.buttons-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;

  button {
    width: 100%;
  }
}
