.help-item {
  display: flex;
  color: white;
  padding: 24px;
  gap: 8px;

  svg {
    width: 40px;
    flex-shrink: 0;
  }

  .help-item-content {
    display: flex;
    gap: 8px;
    flex-direction: column;

    .help-item-title {
      font-size: 15px;
      font-family: ATTAleckSansMd;
    }

    .help-item-desc {
      font-size: 14px;
    }
  }

}