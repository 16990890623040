@import 'src/assets/fonts/AleckSans.scss';
@import 'react-loading-skeleton/dist/skeleton.css';
@import 'src/assets/scss/responsive.scss';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

* {
  font-family: ATTAleckSans, sans-serif;
}

div#root {
  height: 100%;
  display: flex;
  flex-flow: column;
}

.ant-btn {
  display: inline-flex;
  height: 40px;
  padding: 0 24px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  flex-shrink: 0;
  border-radius: 48px;

  span {
    font-family: ATTAleckSansMd;
    font-size: 14px;
  }

  &.ant-btn-primary {
    background: var(--primary-blue);
    color: #000000;
    border-color: var(--primary-blue);

    &:hover {
      path {
        fill: #FFFFFF;
      }
    }
  }

  &.secondary-btn {
    color: var(--primary-blue);
    background: transparent;
    border-color: var(--primary-blue);

    &:hover {
      background: var(--primary-blue);
      color: #000000;

      path {
        fill: #000000;
      }
    }
  }

  &.warning-btn {
    background: #C70032;
    border-color: #C70032;

    &:hover:not(:disabled):not(.ant-btn-disabled):hover {
      background: #DB134A;
      border-color: #DB134A;
      color:white;
    }
  }

  &:disabled {
    color: #32363B;
    background: #686E74;
    border-color: #686E74;
  }

  &.hide {
    display: none;
  }
}

.ant-tooltip {
  .ant-tooltip-inner {
    padding: 12px;
  }
}

.ant-select-dropdown {
  border-radius: 8px;
  background: #494C4D;
  box-shadow: 0 10px 12px -5px rgba(0, 0, 0, 0.06), 0px 16px 24px -5px rgba(0, 0, 0, 0.10);
  padding: 16px;

  .ant-select-item-option-content {
    color: #F8FAFB;
  }

  .ant-select-item.ant-select-item-option.ant-select-item-option-active {
    background-color: #49494f;
  }

  .ant-select-item.ant-select-item-option.ant-select-item-option-selected {
    background-color: #49494f;
  }
}

.breadcrumb {
  display: inline-flex;
  height: 40px;
  padding: 0 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  flex-shrink: 0;
  border-radius: 32px;
  border: 1px solid var(--primary-blue);
  color: var(--primary-blue);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: ATTAleckSansMd;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.42px;

  &:not(.lower) {
    text-transform: capitalize;
  }
}

:root {
  --primary-blue: #43A5F8;
  --secondary-blue: #2495F6;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(--primary-blue);
  border-color: var(--primary-blue);

  &::after {
    border-color: #000000;
  }
}

.ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
  border-radius: 16px;
  border: 1px solid var(--primary-blue);
  background: #2F3132;
}

.ant-radio-wrapper .ant-radio-inner::after {
  width: 24px;
  height: 24px;
  margin-block-start: -12px;
  margin-inline-start: -12px;
  background-color: var(--primary-blue);
}

.ant-dropdown {
  border-radius: 8px;
  background: #494C4D;
  box-shadow: 0 10px 12px -5px rgba(0, 0, 0, 0.06), 0px 16px 24px -5px rgba(0, 0, 0, 0.1);

  .ant-dropdown-menu {
    background: #494C4D;
    padding: 8px;

    .ant-dropdown-menu-title-content {
      color: #F8FAFB;
      font-size: 14px;
    }
  }
}

.toggle {
  font-size: 14px;
  font-style: normal;
  line-height: 20px;
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: space-between;

  .ant-switch {
    height: 14px;
    min-width: 34px;
    padding-top: 2px;
  }

  .ant-switch-handle {
    top: -2.5px;

    &::before {
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    }
  }

  .ant-switch {
    background: #DCDFE3;

    &.ant-switch-checked {
      background: #BAEEFC;
    }
  }

  .ant-switch.ant-switch-checked .ant-switch-handle::before {
    background: var(--primary-blue);
  }
}

.custom-checkbox {
  position: relative;
  cursor: pointer;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    cursor: pointer;
  }

  &::before {
    content: url('assets/svg/checkbox.svg');
    width: 24px;
    height: 24px;
    cursor: pointer;
  }

  &:checked::before {
    content: url('assets/svg/checkbox-checked.svg');
  }

  &:disabled::before {
    cursor: not-allowed;
    content: url('assets/svg/checkbox-disabled.svg');
  }
}

.ant-radio-group {
  display: flex;
  flex-direction: row;
  gap: 16px;
  margin: 16px 0;

  .ant-radio-wrapper {
    color: #F8FAFB;
    font-size: 14px;
  }
}

.app-breadcrumb {
  display: flex;
  font-size: 14px;
  color: #878C94;
  gap: 10px;
  align-items: center;
  line-height: 20px;

  .breadcrumb-back {
    color: #BDC2C7;
    text-decoration: none;
    align-items: center;
    display: flex;
    gap: 10px;
  }

}

.dropdown-dark-btn {
  background: transparent;
  color: white;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  align-items: center;
  height:40px;
  padding: 11px 14px;
  border-radius: 44px;
  border: 1px solid #DCDFE3;
  justify-content: space-between;

  &.ant-select {
    .ant-select-selector {
      background: transparent;
      border: none;
      padding: 0 14px;
      font-size: 14px;
      line-height: 18px;

      .ant-select-selection-item, .ant-select-selection-search {
        color: #fff;
        font-size: 14px;

        .ant-select-selection-search-input {
          height: 100%;
          font-size: 14px;
        }
      }
    }
  }

  &:hover {
    background: none;
  }
}

.dropdown-dark {
  border-radius: 10px;
  background: #1D2329;
  filter: drop-shadow(0px 4px 14px rgba(0, 0, 0, 0.75));

  .ant-empty .ant-empty-description {
    color: #fff;
    font-size: 14px;
  }

  .ant-dropdown-menu {
    background: #1D2329;
    filter: drop-shadow(0px 4px 14px rgba(0, 0, 0, 0.75));
    color: white;

    .ant-dropdown-menu-item {
      height: 40px;

      .ant-dropdown-menu-title-content {
        font-family: ATTAleckSansMd;
        font-size: 14px;
      }

      &:hover {
        background: #0057B8;
      }

      &.active {
        span {
          color: var(--secondary-blue)
        }
        &:hover span{
          color: white;
        }
      }
    }
  }

  .ant-select-item.ant-select-item-option {
    &.ant-select-item-option-active, &:hover, &.ant-select-item-option-selected {
      background: #0057B8;
    }
  }
}

@media (max-width: 1810px) {
  body .anomalies .widgets-header .toggle-switcher {
    position: absolute;
    top: 43px;
    left: 11px;
  }
}


@media (max-width: 1770px) {
  body .grafana-widgets-container {
    zoom: 90%;
  }
}

@media (max-width: 1670px) {
  body .grafana-widgets-container {
    zoom: 80%;
  }
}

@media (max-width: 1530px) {
  body .grafana-widgets-container {
    zoom: 70%;
  }
}
