.settings-wrapper {
  display: flex;
  align-items: center;
  gap:32px;

  .setting-icon {
    border-radius: 48px;
    border: 1px solid var(--primary-blue);
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:hover {
      background-color: #4096ff;

      svg path{
        fill: #000000;
      }
    }
  }

  .sidebar {
    justify-content: normal;
    color: #F8FAFB;
  }
}

.modal-container {
  margin-left: -24px;
}
