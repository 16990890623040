@media (max-width: 1280px) {
  .app-content .security-dashboard {
    margin: auto 30px;
    width: calc(100% - 60px);

    .security-dashboard-content {

      .security-dashboard-top {
        height: 196px;

        .trend-chart {
          flex-direction: column;

          .trend-chart-title {
            margin-bottom: 8px;
          }

          .trend-chart-line {
            flex-direction: row;
            justify-content: space-between;
            padding-top: 0;
            padding-left: 20px;

            .trend-chart-status {
              align-items: end;
              margin-bottom: 10px;
              margin-left: 0;
              margin-right: 0;
            }
          }

          .react-loading-skeleton.chart-trend-line {
            height: 67px;
            width: calc(100% - 40px);
          }
        }
      }

      .security-dashboard-middle {
        height: 400px;

        .box:first-child {
          width: 350px;
        }

        .security-issue-importance  {
          width: 310px;

          .donut-chart-loader {
            width: 180px;
            height: 180px;
          }

          .security-importance-content {
            flex-direction: column-reverse;
            align-items: center;

            .security-legend {
              .security-legend-title {
                display: none;
              }

              .security-legend-items {
                flex-direction: row;
              }
            }

            .no-result {
              width: 155px;
              height: 155px;
              margin: auto;
              border-width: 25px;
            }

            .count-items-loader {
              width: 80px;
            }
          }
        }

        .bar-chart-container {
          width: calc(100vw - 350px - 60px - 14px - 40px);
        }
      }

      .security-dashboard-bottom {
        flex-direction: column;
        margin-bottom: 30px;

        .security-issues-tables {
          width: calc(100% - 48px);

          .table-issue {
            margin-bottom: 24px;
          }
        }
      }
    }
  }
}

@media (max-width: 850px) {
  .app-content .security-dashboard {

    .security-dashboard-content {

      .security-dashboard-top {
        height: auto;
        flex-direction: column;

        .trend-chart {
          flex-direction: row;
          height: 130px;

          .trend-chart-line {
            flex-direction: column-reverse;

            span[aria-live="polite"] {
              width: calc(100% - 40px);
              height: 100%;

              .chart-trend-line {
                width: calc(100% - 20px);
                height: 90px;
                margin-top: 20px;
                margin-right: 20px;
              }
            }

            .trend-chart-status {
              margin-bottom: 20px;
            }
          }
        }
      }

      .security-dashboard-middle {
        height: auto;
        flex-direction: column;

        .box {
          width: 100%;

          &:first-child,  .security-issue-importance {
            width: 100%;
          }

          .bar-chart-container {
            width: calc(100vw - 60px - 20px - 40px);
          }
        }
      }

      .security-dashboard-bottom {
        flex-direction: column;
        height: auto;
      }

      .security-dashboard-help-area {
        flex-direction: column;
      }


    }

    .empty-state-total .empty-state-info {
      width: 80%;
    }
  }
}

@media (max-width: 700px) {

  .header {
    .right .customer-list-btn {
      display: none;
    }

    .menu-container {
      display: none;
    }
  }

  .app-content .security-dashboard {

    .security-dashboard-header {
      align-items: start;
      flex-direction: column;
      gap: 24px;

      .security-dashboard-header-action {
        width: 100%;
        justify-content: space-between;

        .security-dashboard-date-filter {
          width: 100%;

          .range-btn {
            width: calc(100% - 40px);
          }
        }
      }
    }
  }
}

@media (max-width: 500px) {

  .security-dashboard {

    .security-dashboard-bottom {
      .security-issues-tables {
        .security-view-all {
          display: none;
        }

        .table-issue {
          .table {
            .table-header {
              .header-cell:nth-child(4) {
                display: none;
              }
            }
            .table-row {
              .cell {

                &.use-case {
                  max-width: 200px;
                }

                &.apn-number {
                  display: none;
                }
              }
            }
          }
        }
      }

      .security-issue-details.open {
        width: 400px;

        .details {

          .issue-details-widgets {
            flex-direction: column;
          }

        }
      }
    }
  }
}

@media (max-width: 400px) {

  .security-dashboard {

    .security-dashboard-middle {

      .issue-categories .legend {
        margin-left: 0;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 10px;
        margin-top: 16px;
      }
    }

    .security-dashboard-bottom {
      .security-issue-details.open {
        width: 300px;
      }

      .security-issues-tables {
        .table-issue {
          .table {
            .table-header {
              .header-cell:nth-child(3), .header-cell:nth-child(4) {
                display: none;
              }
            }
            .table-row {
              .cell {

                &.use-case {
                  max-width: 200px;
                }

                &.apn-number, &.devices {
                  display: none;
                }
              }
            }
          }
        }
      }
    }
  }
}