.sidebar-filter-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 40px;

  .filter {
    display: flex;
    flex-direction: column;
    color: #F8FAFB;
    font-style: normal;

    .filter-title {
      font-size: 20px;
      font-weight: 700;
      line-height: 28px;
    }

    .filter-subtitle {
      font-feature-settings: 'clig' off, 'liga' off;
      font-size: 14px;
      font-weight: 500;
      line-height: normal;
      margin-bottom: 8px;
      margin-top: 24px;
    }

    .filter-list {

      .ant-select-selector {
        border-radius: 8px;
        border: 1px solid #686E74;
        background: #212324;
        color: white;
        padding: 0 14px;
        font-size: 14px;
        line-height: 18px;

        .ant-select-selection-item {
          color: white;
        }
      }

      .ant-select-arrow {
        background: no-repeat center url('./../../../../assets/svg/chevron-down.svg');
      }
    }
  }
}
