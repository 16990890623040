.fields-wrapper {
  display: flex;
  flex-direction: column;

  .field {
    padding: 32px 16px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #454B52;
    color: #F8FAFB;
    font-size: 14px;
    min-height: 32px;
    width: 100%;

    .label-with-tooltip {
      display: flex;
      align-items: center;
      flex-basis: 15%;

      .input-label {
        font-family: ATTAleckSansMd;
        text-align: left;
        display: flex;
        align-items: center;
        gap: 8px;
      }

      span {
        display: flex;
      }
    }

    .input-wrapper {
      width: 100%;
    }

    .number-select-wrapper {
      display: flex;
      width: 100%;

      .ant-select-selector {
        background: transparent;
        border-radius: 8px;
        border: 1px solid #878C94;
        color: #BDC2C7;
        margin-left: 24px;

        .ant-select-selection-item {
          background: rgba(0, 0 , 0, 0.2);
        }

        .ant-select-selection-item-remove {
          color: #BDC2C7;
        }

        .ant-select-selection-placeholder {
          font-size: 16px;
          color: #BDC2C7;
          padding: 12px 0;
        }
      }
    }
  }
}