.dropdown-custom {
  border-radius: 8px;
  background: #2F3132;
  box-shadow: 0 10px 12px -5px rgba(0, 0, 0, 0.06), 0px 16px 24px -5px rgba(0, 0, 0, 0.10);
  padding: 16px;

  .checkbox-option {
    padding: 12px;
    border-bottom: 1px solid #454B52;
    border-radius: 8px;

    .ant-checkbox-wrapper {
      width: 100%;
    }

    span {
      color: #F3F4F6;
    }

    &:hover {
      background: rgba(255, 255, 255, 0.08);
    }

    &:last-child {
      border-bottom: none;
    }
  }
}

.checkbox-select{
  &.ant-select-multiple {
    width: 410px;
  }

  &.ant-select-single {
    .ant-select-selector .ant-select-selection-item {
      color: #BDC2C7;
      background: transparent;
    }
  }
}
