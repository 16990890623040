.general-wrapper {
  padding: 24px;

  .general-title {
    color: #F8FAFB;
    font-family: ATTAleckSansBd;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    margin-bottom: 5px;
  }

  .general-subtitle {
    color: #F8FAFB;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 20px;
  }
}
