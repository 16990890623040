.security-issues-tables {
  display: flex;
  flex-direction: column;
  padding: 24px;
  width: 820px;
  height: calc(100% - 48px);

  .security-issues-tables-header {
    display: flex;
    color: white;
    justify-content: space-between;
    margin-bottom: 26px;

    .security-issues-tables-title {
      font-size: 20px;
      font-family: ATTAleckSansMd;
    }

    .security-issues-tables-stats {
      display: flex;
      gap: 13px;

      div {
        border-radius: 8px;
        padding: 0 8px;
        display: flex;
        align-items: center;
        text-transform: uppercase;
        letter-spacing: 0.04em;
        height: 40px;

        span {
          font-family: ATTAleckSansMd;
          font-size: 12px;

          &:last-child {
            font-size: 27px;
          }
        }

        &.total {
          background: #31353D;
          gap: 22px;
        }

        &.high {
          color: #C70032;
          background: rgba(255, 165, 187, 0.08);
          gap: 8px;
        }
      }
    }
  }

  .tables-issues-wrapper {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    flex-grow: 1;
  }

  .empty-state-total {
    background: #31353D;
    color: white;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    height: 100%;
    padding: 24px;
    text-align: center;

    .empty-state-title {
      font-size: 20px;
      font-family: ATTAleckSansMd;
    }

    .empty-state-desc {
      font-size: 16px;
      font-family: ATTAleckSansMd;
      text-align: center;
    }

    .empty-state-info {
      font-family: ATTAleckSansMd;
      font-size: 16px;
      width: 50%;
      text-align: center;
    }
  }
}
