.destination-tree {
  height: 420px;
  position: relative;

  .overlay {
    position: absolute;
    left: 50%;
    top: 45%;

    .full-screen-loader {
      position: absolute;
      width: calc(100% - 16px);
      height: calc(100% - 16px);
      left: 8px;
      top: 8px;
      background: #000000;
    }
  }

  .destination-tree-content {

    input[type="checkbox"]::before {
      background: #2F3132;
    }

    .tree-header {
      display: flex;
      font-weight: bold;
      padding: 10px 22px;
      border-bottom: 1px solid #454B52;
      background: #353739;
      border-radius: 10px 10px 0 0;
      flex-direction: column;
      gap: 8px;
      justify-content: space-between;

      input[type="checkbox"] {
        margin-right: 24px;
      }

      input[type="text"] {
        border: 1px solid #DCDFE3;
        border-radius: 8px;
        height: 35px;
        background: transparent;
        outline: none;
        padding: 0 12px;
        color: #DCDFE3;
      }

      .tree-header-row {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 30px;

        &:first-child {
          justify-content: space-between;
        }

        path {
          fill: #edeff0;
        }

        .asc {
          transform: rotate(180deg);
        }

        .hide {
          display: none;
        }
      }

      .node {
        display: flex;
        align-items: center;
        cursor: pointer;

        .reset-button-style {
          background: none;
          border: none;
          padding: 0;
          margin: 0;
          font: inherit;
          color: inherit;
          cursor: pointer;
        }
      }

      .node-title {
        margin-left: 52px;
        width: 210px;
      }

      .node-lists-wrapper {
        width: 160px;
      }

      .node-traffic {
        width: 100px;
      }

      .node-percentage {
        width: 100px;
      }
    }

    .tree-content {
      max-height: 280px;
      overflow-y: scroll;

      .no-result {
        height: 280px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 28px;
        color: #686e74;
      }
    }

    .tree-node {
      position: relative;
      padding-left: 20px;
      display: flex;
      flex-direction: column;

      &::after {
        content: '';
        position: absolute;
        top: 50%;
        left: -2px;
        width: 20px;
        height: 1px;
        background: #686E74;
      }

      &.level-0 {
        margin-left: 0;
        border-bottom: 1px solid #454B52;

        &::after {
          content: none;
        }
      }

      .tree-children {
        margin-left: 20px;
        position: relative;

        &.expanded {
          display: block;

        }

        &::before {
          content: "";
          position: absolute;
          top: -20px;
          left: -4px;
          bottom: 36px;
          width: 1px;
          background: #686E74;
          height: calc(100% - 22px);
        }

        .tree-node:last-child::after {
          content: "";
          width: 18px;
          height: 18px;
          position: absolute;
          border-bottom: 1px solid #686E74;
          border-left: 1px solid #686E74;
          border-bottom-left-radius: 50%;
          bottom: 0;
          left: -4px;
          background: #2F3132;
          top: 20px;
        }
      }
    }

    .tree-node-content {
      display: flex;
      align-items: center;
      position: relative;
      padding: 22px 0;
      justify-content: space-between;

      .custom-checkbox {
        margin-right: 28px;
      }

      .node-title {
        flex-basis: 210px;
        max-width: 210px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        &.key-include {
          font-family: ATTAleckSansBd;
        }
      }

      .node-lists-wrapper {
        flex-basis: 160px;

        .node-lists {
          display: inline-block;
          max-width: 140px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          vertical-align: middle;
          cursor: pointer;
          position: relative;

          .bold-ip {
            font-family: ATTAleckSansBd;
          }
        }
      }

      .node-traffic, .node-percentage {
        flex-basis: 100px;
      }

      .arrow {
        cursor: pointer;
        position: absolute;
        right: 11px;
        transition: transform 0.3s ease;
        border: none;
        background: none;

        &.collapse{
          transform: rotate(180deg);
        }

        &.expanded {
          transform: rotate(270deg);
        }
      }
    }

    .tree-footer {
      padding-top: 18px;
      border-top: 1px solid #454B52;
      padding-bottom: 12px;
      display: flex;
      gap: 10px;
      justify-content: end;

      .percentage-display, .total-checked-mb {
        padding: 0 27px;
        height: 40px;
        gap: 6px;
        border-radius: 20px;
        display: flex;
        background: #353739;
        color: #F3F4F6;
        align-items: center;
        width: fit-content;
        font-size: 18px;

        span {
          font-family: ATTAleckSansBd;
        }
      }
    }
  }
}

.tooltip-node-ips {
  .bold-ip {
    font-family: ATTAleckSansBd;
  }
}
