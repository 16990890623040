.issue-categories {
  padding: 20px 20px 28px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100% - 48px);

  .title {
    color: #F8FAFB;
    font-size: 20px;
    font-family: ATTAleckSansMd;
  }

  .legend {
    display: flex;
    gap: 25px;
    margin-left: 16px;

    .legend-item {
      display: flex;
      align-items: center;
      gap: 8px;

      .legend-color {
        width: 22px;
        height: 15px;
        border-radius: 10px;
      }

      .legend-text {
        color: white;
        font-size: 12px;
      }
    }
  }
}
